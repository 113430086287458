var Filters, query, url_array;

$('.toggler.filter input[type=checkbox]').on('change', function(e) {
  var active, history, name;
  e.preventDefault();
  window.scrollTo(0, 0);
  active = $(this).prop('checked');
  name = $(this).attr('name');
  TorrentsTable.filter_table(name, !active);
  history = {};
  history[name] = active;
  return History.pushState($.extend(STATE.data || {}, history));
});

url_array = url('path').split('/');

query = url('?');

if (url_array[url_array.length - 1] === 'search') {
  if ((query != null) && (query.q != null)) {
    $('input[name="q"]').val(query.q);
  }
  $(".search-breadcrumbs").removeClass("hidden");
}

Filters = (function() {
  return {
    init: function() {
      var self;
      self = this;
      $(".filter-element[data-type='time']").on('mousedown', function(e) {
        var i, j, new_url, ref;
        e.preventDefault();
        if (TorrentsTable.filters.time === 4) {
          $('.search-form')[0].children[0].value = "";
          if (url_array[url_array.length - 1] === 'search') {
            url_array.length = url_array.length - 1;
          }
          new_url = '/';
          for (i = j = 0, ref = url_array.length; 0 <= ref ? j <= ref : j >= ref; i = 0 <= ref ? ++j : --j) {
            if (url_array[i]) {
              new_url = new_url + url_array[i] + '/';
            }
          }
          history.pushState({}, null, new_url);
          document.title = 'Top List - BitLord Power Search';
          TorrentsTable.search(null);
          $(".all_time_filter").addClass("hidden");
          return $('.search-breadcrumbs').addClass('hidden');
        }
      });
      $('.filter-element').on('mousedown', function(e) {
        e.preventDefault();
        window.scrollTo(0, 0);
        return self.filter($(this));
      });
      $('.search-form').on('submit', function(e) {
        var categoty_id, table;
        e.preventDefault();
        query = $('.prompt').val();
        if (query.length < 3) {
          query = null;
        }
        if (query != null) {
          window.scrollTo(0, 0);
          history.pushState({}, null, 'search?q=' + query);
          document.title = query + ' - BitLord Power Search';
          table = TorrentsTable;
          categoty_id = table.filters.time = 4;
          table.search(query);
          $(".all_time_filter").removeClass("hidden");
          Filters.search_breadcrumbs(query);
          return self.filter($(".all_time_filter"), false);
        }
      });
      return this;
    },
    filter: function(element, request) {
      var index, type;
      if (request == null) {
        request = true;
      }
      type = element.data('type');
      index = element.data('index');
      if (element.hasClass('in-table')) {
        element = $(".filter-element.in-header[data-type='" + type + "'][data-index='" + index + "']");
      }
      element.parents('.nav.nav-pills').find('a').removeClass('active');
      element.parents('.dropdown-menu').find('a').removeClass('active');
      element.find('a').addClass('active');
      if (request) {
        TorrentsTable.filter_table(type, index);
      }
      return $('.filter-element').on('mousedown', function(e) {
        return Filters.search_breadcrumbs($('input[name="q"]').val());
      });
    },
    search_breadcrumbs: function(query_) {
      var category, in_categoty;
      if (TorrentsTable.filters.time === 4) {
        category = $(".filter-element.in-header[data-type='category']").find('a.active')[0].text;
        in_categoty = category !== 'All' ? ' in category <b>' + category + '</b>' : '';
        $(".search-breadcrumbs").html('Results for <b>' + query_ + '</b>' + in_categoty);
        return $(".search-breadcrumbs").removeClass("hidden");
      }
    }
  };
})();

$(document).ready(function() {
  return Filters.init();
});
